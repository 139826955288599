import getConfig from 'next/config'

import { GoogleMapsSettings, MarkerClusters, Pin } from '@grandvisionhq/common'
import { Components } from '@grandvisionhq/stores-v2'

const { publicRuntimeConfig } = getConfig()
const gmKey = publicRuntimeConfig.googleMaps.apiKey ?? ''
const gmCenter = publicRuntimeConfig.googleMaps.mapCenter ?? {}

const { PreferredStorePin } = Components

const pinThemes = {
  primary: { foreground: '#ffffff', background: '#089583' },
  highlight: { foreground: '#ffffff', background: '#033C34' },
  hovered: { foreground: '#033C34', background: '#ffffff' },
}

export const getGoogleMapsConfig = (): GoogleMapsSettings => ({
  apiKey: gmKey,
  defaultCenter: gmCenter,
  defaultZoom: 6,
  pin: Pin({
    themes: pinThemes,
  }),
  preferredStorePin: PreferredStorePin({
    themes: pinThemes,
  }),
  markerClusterOptions: MarkerClusters({
    theme: {
      color: '#089583',
      textColor: '#ffffff',
    },
    minimumClusterSize: 3,
    maxZoom: 10,
  }),
})
