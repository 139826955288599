import { CommerceToolsApi } from '@grandvisionhq/commercetools'

type Specification = {
  labelKey: string
  attribute: string
  isLocalised?: boolean
  isSet?: boolean
  labelPrefix?: string
}

export function getSpecifications(productType: string): Specification[] | undefined {
  if (
    CommerceToolsApi.isSunglasses(productType) ||
    CommerceToolsApi.isFrames(productType) ||
    CommerceToolsApi.isReadyReaders(productType)
  ) {
    return [
      {
        labelKey: 'products.specifications.lensColor',
        attribute: 'lensColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
        isSet: true,
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameType',
        attribute: 'frameType',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.gender',
        attribute: 'gender',
        isLocalised: true,
        isSet: true,
      },
      {
        labelKey: 'products.specifications.roadworthiness',
        attribute: 'roadworthiness',
        labelPrefix: 'products.specifications.roadworthiness.',
      },
    ]
  }

  if (CommerceToolsApi.isAccessories(productType)) {
    return [
      {
        labelKey: 'products.specifications.accessoryColor',
        attribute: 'accessoryColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.material',
        attribute: 'material',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.erpId',
        attribute: 'erpId',
        isLocalised: false,
      },
    ]
  }
}
