import { Config } from '@grandvisionhq/shipping'

const config: Config = {
  inventoryChannels: {
    // test
    '2fdfd1e3-f341-4a16-b09d-0369a7fd317e': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // acc
    'fc321034-6731-471b-927a-aff030c10631': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // prod
    'e0f97603-50c5-49c8-be99-f54498bcbc3b': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '8',
  },
  nextDayDeliveryTimeLimit: '17:30',
}

export const getDeliveryIndicatorConfig = () => config
